body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f5f6f9;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
	text-decoration: none;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins Bold'), local('Poppins-Bold'),
		url('./assets/fonts/Poppins-Bold.woff2') format('woff2'),
		url('./assets/fonts/Poppins-Bold.woff') format('woff'),
		url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins Semi Bold'), local('Poppins-SemiBold'),
		url('./assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
		url('./assets/fonts/Poppins-SemiBold.woff') format('woff'),
		url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins Medium'), local('Poppins-Medium'),
		url('./assets/fonts/Poppins-Medium.woff2') format('woff2'),
		url('./assets/fonts/Poppins-Medium.woff') format('woff'),
		url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
